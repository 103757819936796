function openPurchaseForm(skuData) {
    return new Promise(function(resolve, reject) {
    });
}

function validateStep1() {
    var hasError = false;

    var cardName = $('#ccName'),
        cardNum = $('#ccNumber'),
        cardExpM = $('#ccExpM'),
        cardExpY = $('#ccExpY'),
        cardCVV = $('#ccCVV');

    var allInputs = [
        cardName,
        cardNum,
        cardExpM,
        cardExpY,
        cardCVV
    ];

    allInputs.forEach(function (o) {
        o.closest('div').removeClass('has-error');
    });

    allInputs.forEach(function (o) {
        if (o.val().length === 0) {
            hasError = true;
            o.closest('div').addClass('has-error');
        }
    });

    if (!$.payform.validateCardNumber(cardNum.val())) {
        hasError = true;
        cardNum.closest('div').addClass('has-error');
    }

    return !hasError;
}

function validateStep2() {
    var hasError = false;

    var cardBillingCountry = $('#ccBillingCountry'),
        cardBillingHouseNum = $('#ccBillingHouseNum'),
        cardBillingAddress = $('#ccBillingAddress'),
        cardBillingCity = $('#ccBillingCity'),
        cardBillingProvince = $('#ccBillingProv'),
        cardBillingPostal = $('#ccBillingPostal'),
        cardBillingPhone = $('#ccBillingPhone');

    var allInputs = [
        cardBillingCountry,
        cardBillingHouseNum,
        cardBillingAddress,
        cardBillingCity,
        cardBillingProvince,
        cardBillingPostal,
        cardBillingPhone
    ];

    allInputs.forEach(function (o) {
        o.closest('div').removeClass('has-error');
    });

    allInputs.forEach(function (o) {
        if (o.val().length === 0) {
            hasError = true;
            o.closest('div').addClass('has-error');
        }
    });

    return !hasError;
}

function validateStep3() {
    var hasError = false;

    if (!$('#toc').is(':checked')) {
        hasError = true;
        $('#toc').closest('div').addClass('has-error');
    }

    return !hasError;
}

$(function() {

    if($('#purchase').length) {

        // external purchase api obj
        window.parlayApi.purchase = {
            getProducts: function() {
                return new Promise(function(resolve, reject) {
                    $.get(nodeHost + '/purchase/products', function(data) {
                        resolve(data);
                    });
                });
            },

            purchaseProduct: function(sku) {
                return new Promise(function(resolve, reject) {
                    $.get(nodeHost + '/purchase/products', function(data) {

                        var skuData = _.find(data, function(obj) {
                            return obj.productId === sku;
                        });

                        resolve(skuData);
                    });
                }).then(function(skuData) {
                    return new Promise(function(resolve, reject) {
                        // remove any old click listeners so we can pass promise to game
                        $('.btn-step3').off();

                        $('#buyDetails')
                            .removeClass('bingochip-icon', 'powerup-icon')
                            .addClass(skuData.productId.split('.')[3] + '-icon')
                            .html(skuData.description);

                        $('#productSku').val(skuData.productId);
                        $('#buyCost').html(skuData.price);

                        $('.btn-step3').click(function () {

                            if (validateStep3()) {

                                var loginData = window.localStorage.getItem(
                                    (window.localStorage.getItem('loginData') !== null) ? 'loginData' : 'guestData'
                                );

                                loginData = JSON.parse(loginData);

                                var formValues = {
                                    sessToken: loginData.token,
                                    userId: loginData.userId,
                                    cardName: $('#ccName').val(),
                                    cardNum: $('#ccNumber').val(),
                                    cardExpM: $('#ccExpM').val(),
                                    cardExpY: $('#ccExpY').val(),
                                    cardCVV: $('#ccCVV').val(),
                                    cardBillingCountry: $('#ccBillingCountry').val(),
                                    cardBillingHouseNum: $('#ccBillingHouseNum').val(),
                                    cardBillingAddress: $('#ccBillingAddress').val(),
                                    cardBillingAPT: $('#ccBillingApt').val(),
                                    cardBillingCity: $('#ccBillingCity').val(),
                                    cardBillingProvince: $('#ccBillingProv').val(),
                                    cardBillingPostal: $('#ccBillingPostal').val(),
                                    cardBillingPhone: $('#ccBillingPhone').val(),
                                    toc: $('#toc:checked').val(),
                                    keepCard: $('#keepCard:checked').val()
                                };

                                if (_.isUndefined(formValues.keepCard)) {
                                    formValues.keepCard = 'no';
                                }

                                $.post(nodeHost + '/purchase/' + $('#productSku').val(),
                                    formValues,
                                    function (data) {
                                        console.log(data);
                                        $('#purchase').addClass('hide').removeClass('show');
                                        resolve(data);
                                    }
                                );
                            }
                        });

                        $('#purchase').addClass('show').removeClass('hide');
                    });
                });
            }
        };

        $('#closePurchaseModal').click(function () {
            $('#purchase').addClass('hide').removeClass('show');
        });

        $('.step1 form').find('input:not(input[name="ccNumber"]), select').on("focusout", function () {
            $(this).parent().removeClass('has-error');
        });

        $('#ccCVV').payform('formatCardCVC');
        $('#ccNumber')
            .payform('formatCardNumber')
            .on('input', function () {
                var cardType = $.payform.parseCardType($(this).val());
                var imgEl = $('.ccNumGroup img');
                switch (cardType) {
                    case 'visa':
                        imgEl.show().attr('src', 'img/purchase/cards/visa.svg');
                        break;
                    case 'mastercard':
                        imgEl.show().attr('src', 'img/purchase/cards/mastercard.svg');
                        break;
                    case 'amex':
                        imgEl.show().attr('src', 'img/purchase/cards/amex.svg');
                        break;
                    case 'jcb':
                        imgEl.show().attr('src', 'img/purchase/cards/jcb.svg');
                        break;
                    case 'discover':
                        imgEl.show().attr('src', 'img/purchase/cards/discover.svg');
                        break;
                    case 'dinersclub':
                        imgEl.show().attr('src', 'img/purchase/cards/dinersclub.svg');
                        break;
                    default:
                        imgEl.hide();
                        break;
                }
            })
            .on('focusout', function () {
                if (!$.payform.validateCardNumber($(this).val())) {
                    $(this).parent().addClass('has-error');
                } else {
                    $(this).parent().removeClass('has-error');
                }
            });

        $('.btn-step1').click(function () {

            if (validateStep1()) {
                $('.step1').hide();
                $('.step2').show();

                $('.step1-badge').removeClass('active').addClass('complete');
                $('.step2-badge').removeClass('inactive').addClass('active');
            }
        });

        $('.btn-step1-back').click(function () {
            $('.step1').show();
            $('.step2').hide();

            $('.step1-badge').removeClass('complete').addClass('active');
            $('.step2-badge').removeClass('active').addClass('inactive');
        });

        $('.btn-step2').click(function () {

            if (validateStep2()) {

                var ccNum = $('#ccNumber').val().split(' ');
                var ccNumLastDigits = ccNum[ccNum.length - 1];

                var currDate = new Date();


                $('#inGamePurchase-verifyCC').html('**** **** **** ' + ccNumLastDigits);
                $('#inGamePurchase-verifyExp').html($('#ccExpM').val() + '/' + $('#ccExpY').val());
                $('#inGamePurchase-verifyDate').html(currDate.toLocaleDateString('en-us', {month: "long"}) + ' ' + currDate.getDay() + ', ' + currDate.getFullYear());


                $('.step2').hide();
                $('.step3').show();

                $('.step2-badge').removeClass('active').addClass('complete');
                $('.step3-badge').removeClass('inactive').addClass('active');
            }
        });

        $('.btn-step2-back').click(function () {
            $('.step2').show();
            $('.step3').hide();

            $('.step2-badge').removeClass('complete').addClass('active');
            $('.step3-badge').removeClass('active').addClass('inactive');
        });
    }
});