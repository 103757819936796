var VERSION = "v6.0.0";
var game_style = null;
var game_lang = null;
var site_links = null;
var game_type = null;
var pgs_root = null;
var node_root = null;
var isMobile = null;

window.parlayApi = window.parlayApi || {};

var nodeHost = window.parlayApi.env.nodeHost;
var gameStyle = window.parlayApi.env.gameStyle;

if(location.hostname === "www.bingolands.com" || location.hostname === "bingolands.com") {
    Raven.config('https://0149f4e29404448f8065e12d0b851d01@sentry.io/216734').install();
}

function validateForm(reqInputs) {
    var hasError = false;

    reqInputs.forEach(function (o) {
        o.closest('div').removeClass('has-error');
    });

    reqInputs.forEach(function (o) {
        if (o.val().length === 0) {
            hasError = true;
            o.closest('div').addClass('has-error');
        }
    });

    return !hasError;
}

/** Turn error codes into nicer error messages */
function fancify(msg) {
    var transMatrix = {
        'error.userid.notfound': "Invalid login credentials given, please try again"
    };

    return _.isUndefined(transMatrix[msg]) ? msg : transMatrix[msg];
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function showLoader(elForm) {
    $('.loader').show();
    elForm.addClass('processing');
    elForm.find('input, select').prop('readonly', true);
    elForm.find('button, submit').attr('disabled', 'disabled');
}

function hideLoader(elForm) {
    $('.loader').hide();
    elForm.removeClass('processing');
    elForm.find('input, select, button').prop('readonly', false);
    elForm.find('button, submit').removeAttr('disabled', '');
}

function showMsgBox(msg, type) {
    type = _.isUndefined(type) ? 'alert-danger' : type;

    $('#msgBox')
        .removeClass('hide alert-danger alert-success')
        .addClass(type)
        .find('.inner-alert')
        .html(msg);
}

function closeModal($this) {
    $this.closest(".modal").removeClass('show').addClass('hide');
}

function SSOLogin(ssoData) {
    console.log('SSO Login init', ssoData);

    var locale = ssoData.locale.split('_')[0];

    $.getJSON(nodeHost + '/app-login-sso?callback=?', {
        locale          : locale,
        style           : gameStyle,
        'sso-id'        : ssoData.SSOID,
        'sso-provider'  : ssoData.SSOProvider

    }, function(loginResp) {
        console.log('post SSO', loginResp);

        // is registered yet in HE?
        if(loginResp.success) {
            loadGame(loginResp.data);

        } else if (!loginResp.success && loginResp.msg === 'user.not.found') {

            $.getJSON(nodeHost + '/app-register?callback=?', {
                SSOID       : ssoData.SSOID,
                SSOProvider : ssoData.SSOProvider,
                locale      : locale,
                style       : gameStyle,
                first_name  : ssoData.first_name,
                last_name   : ssoData.last_name,
                email       : ssoData.email,
                gender      : ssoData.gender,
                currency    : ssoData.currency

            }, function(regData) {
                console.log('app-register', regData);

                if(regData.success) {
                    loadGame(regData.data);
                } else {
                    showMsgBox('<strong>Bad Login</strong> ' + regData.msg);
                }
            });
        }
    });
}

function SSOLink(userId, ssoData) {
    return new Promise(function(resolve, reject) {
        console.log('SSOLink', userId, ssoData);

        $.getJSON(nodeHost + '/app-login-link-sso?callback=?', {
            userId      : userId,
            SSOID       : ssoData.SSOID,
            SSOProvider : ssoData.SSOProvider

        }, function (linkData) {
            console.log('app-login-link-sso', linkData);

            if (linkData.success) {
                resolve({userId: userId, ssoData: ssoData});
            } else {
                reject();
            }
        });
    });
}

function registerGuest(userId, params) {
    return new Promise(function(resolve, reject) {
        $.getJSON(nodeHost + '/app-register-guest?callback=?', {
                userId      : userId,
                first_name  : params.firstName,
                last_name   : params.lastName,
                email       : params.email,
                password    : params.password

            }, function (regData) {
                console.log('registerGuest', regData);

                if (regData.success) {
                    resolve({userId: userId, params: params});

                } else {
                    reject({userId: userId, params: params});
                }
            });
        });
}

function loadGame(data, isGuest) {
    console.log('loadGame', data);
    isGuest = typeof isGuest === "undefined" ? false : isGuest;

    window.localStorage.setItem((isGuest === true) ? 'guestData' : 'loginData', JSON.stringify({
        token: data.token,
        userId: data.userId
    }));

    game_style = gameStyle;
    game_lang = data.lang;
    site_links = data.siteLinksUrl;
    game_type = data.gameType;
    pgs_root = data.assetsUrl;
    node_root = data.nodeUrl;
    isMobile = data.isMobile;
    window.parlay_slots = data.passthroughData;

    var params = {
        game_style  : gameStyle,
        game_lang   : data.lang,
        site_links  : data.siteLinksUrl,
        game_type   : data.gameType,
        pgs_root    : data.assetsUrl,
        node_root   : data.nodeUrl,
        isMobile    : data.isMobile,
        passThrough : data.passthroughData
    };

    WebFont.load({
        custom: {
            families: ['cartoonist_kooky', 'riffic'],
            urls: ['fonts/socialBingo_fonts.css']
        },
        timeout: 3000
    });

    // remove un-needed forms as it can cause keyboard issues if left in focus
    $('.page-wrap').hide();
    $('#msgBox').removeClass('show');

    // Hook in game code, see bin folder
    startUp(params, isGuest);
}

// --------------------------------------------------

window.parlayApi.account = {
    logout: function() {
        window.localStorage.removeItem('loginData');
        location.reload();
    },
    showGuestRegister: function(callback) {
        $('#inGameConnect').removeClass('hide').addClass('show');

        if(typeof callback !== "undefined") {
            window.parlayApi.account._guestRegisterCallback = callback;
        }
    }
};

document.addEventListener('deviceready', function() {
    StatusBar.hide();

    window.parlayApi.buildInfo = BuildInfo;
});

// --------------------------------------------------

$(function() {

    $('.connect-btn').click(function() {
        $("#landing").hide();
        $("#connect").show();
    });

    $('.account-btn').click(function() {
        $("#register").show();
        $("#connect").hide();

    });

    $('.login-link').click(function() {
        $('#login').show();
        $("#connect").hide();
    });

    $('.forgot-password').click(function() {
        $('#forgotPassword').show();
        $("#login").hide();
    });

    $('.back-btn, .back-btn-full').click(function() {
        $("#landing").show();
        $("#login, #connect, #register, #forgotPassword").hide();
    });

    $('.account-modal-btn').click(function() {
        $('#inGameConnect').removeClass('show').addClass('hide');
        $('#inGameRegister').removeClass('hide').addClass('show');
    });

    $('.back-modal-btn').click(function() {
        $('#inGameRegister').removeClass('show').addClass('hide');
        $('#inGameConnect').removeClass('hide').addClass('show');
    });

    $('.close-modal').click(function() {
        $(this).closest(".modal").removeClass('show').addClass('hide');
        $('#login').addClass('show').removeClass('hide');
    });

    $('.close-modal-only').click(function() {
        closeModal($(this));
    });

    $('#msgBox .close').click(function() {
        $(this).parent().addClass('hide');
    });

    $('.guest-btn').click(function() {
        var guestData = window.localStorage.getItem('guestData');
        var noGuestId = false;

        function doFullGuestLogin() {
            $.getJSON(nodeHost + '/app-guest?callback=?', { }, function(data) {
                console.log('login data', data);

                if(data.success) {
                    if(_.isUndefined(data.data.lang)) {
                        data.data.lang = 'en';
                    }
                    loadGame(data.data, true);

                } else {
                    showMsgBox('<strong>Bad Login</strong> ' + data.msg.message);
                    $('.login-form .form-group').addClass('has-error');
                }
            });
        }

        if(guestData !== null) {
            var parsedloginData = JSON.parse(guestData);

            $.getJSON(nodeHost + '/app-relogin?callback=?', {
                token       : parsedloginData.token,
                userId      : parsedloginData.userId,
                gameStyle   : gameStyle

            }, function(data) {
                if(data.success) {
                    if(_.isUndefined(data.data.lang)) {
                        data.data.lang = 'en';
                    }
                    loadGame(data.data, true);

                } else {
                    // We had old guestData, scrub cache
                    window.localStorage.removeItem('guestData');
                    guestData = null;

                    doFullGuestLogin();
                }
            });
        } else {
            doFullGuestLogin();
        }

        return false;
    });

    $('#login-form').submit(function(e) {
        e.preventDefault();
        var values = {};

        // $('.loader').show();
        $('#login-form .form-group').removeClass('has-error');


        $(this).serializeArray().forEach(function(o) {
            values[o.name] = o.value;
        });

        values.lang = 'en';
        values.style = gameStyle;

        $.getJSON(nodeHost + '/app-login?callback=?', values, function(data) {
            console.log('login data', data);
            // $('.loader').hide();

            if(data.success) {
                loadGame(data.data);

            } else {
                showMsgBox('<strong>Bad Login</strong> ' + data.msg.message);
                $('.login-form .form-group').addClass('has-error');
            }
        });

        return false;
    });

    $('#forgot-password-form').submit(function() {
        $.getJSON(nodeHost + '/account/forgot-password', {
            recoveryEmail: $('#fpEmail').val()
        }, function(fpData) {
            console.log('account/forgot-password', fpData);
            $('.loader').hide();

            if(fpData.success) {
                showMsgBox('<strong>Lookin Good!</strong> Password reset instructions were sent to your recovery email.', 'alert-success');
                $('#forgotPassword').removeClass('show').addClass('hide');
                $('#landing').addClass('show').removeClass('hide');

            } else {
                showMsgBox('<strong>Oops!</strong> ' + fpData.msg);
                $('.login-form .form-group').addClass('has-error');
            }
        });

        return false;
    });

    $('#password-reset-form').submit(function() {
        $.getJSON(nodeHost + '/account/forgot-password-finish', {
            password: $('#prPassword').val(),
            token: getParameterByName('token')
        }, function(fpfData) {

            console.log('account/forgot-password-finish', fpfData);

            // if(fpData.success) {
            //     showMsgBox('<strong>Lookin Good!</strong> Password reset instructions were sent to your recovery email.', 'alert-success');
            //     $('#forgotPassword').closest(".modal").removeClass('show').addClass('hide');
            //     $('#login').addClass('show').removeClass('hide');
            //
            // } else {
            //     showMsgBox('<strong>Oops!</strong> ' + fpData.msg);
            //     $('.login-form .form-group').addClass('has-error');
            // }
        });

        return false;
    });

    $('#register-form').submit(function() {
        var values = {};

        // $('.loader').show();
        $('#register-form .form-group').removeClass('has-error');

        $(this).serializeArray().forEach(function(o) {
            values[o.name] = o.value;
        });

        $.getJSON(nodeHost + '/app-register?callback=?', {
            locale      : 'en',
            style       : gameStyle,
            first_name  : values.firstName,
            last_name   : values.lastName,
            email       : values.email,
            password    : values.password,
            currency    : 'CAD'

        }, function(regData) {
            console.log('app-register', regData);
            // $('.loader').hide();

            if(regData.success) {
                loadGame(regData.data);

            } else {
                showMsgBox('<strong>Oops!</strong> ' + regData.msg);
                $('.login-form .form-group').addClass('has-error');
            }
        });

        return false;
    });

    $('#register-guest-form').submit(function() {
        var values = {};
        var guestData = window.localStorage.getItem('guestData');

        $(this).find('.form-group').removeClass('has-error');
        $(this).serializeArray().forEach(function(o) {
            values[o.name] = o.value;
        });

        if(guestData === null) {
            showMsgBox("<strong>Hmm</strong> It seems we can't find your guest account ID. Please contact support for help");

        } else {
            guestData = JSON.parse(guestData);

            registerGuest(guestData.userId, values)
            .then(function() {
                closeModal($('#inGameConnect, #inGameRegister'));
                if(typeof window.parlayApi.account._guestRegisterCallback !== "undefined") {
                    window.parlayApi.account._guestRegisterCallback(true);
                }
            })
            .catch(function(err) {
                showMsgBox('<strong>Oops!</strong> ' + err);
                $('.login-form .form-group').addClass('has-error');
            });
        }

        return false;
    });

    var loginData = window.localStorage.getItem('loginData');

    if(loginData !== null) {
        var parsedloginData = JSON.parse(loginData);

        $.getJSON(nodeHost + '/app-relogin?callback=?', {
            token       : parsedloginData.token,
            userId      : parsedloginData.userId,
            gameStyle   : gameStyle

        }, function(data) {
            if(data.success) {
                loadGame(data.data);
            } else {
                // We had old loginData, scrub cache
                window.localStorage.removeItem('loginData');
                loginData = null;
            }
        });
    }
});

function queryString(val) {
    var result = {}, queryString = location.search.slice(1),
        re = /([^&=]+)=([^&]*)/g, m;

    while ((m = re.exec(queryString))) {
        result[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }

    return (!_.isUndefined(val)) ? result[val] : result;
}

if($('#linkAccount').length > 0) {

    $.getJSON(nodeHost + '/link/finish?callback=?', {
        token  : queryString('token'),
        userId : queryString('userId')

    }, function(loginResp) {
        console.log('post link', loginResp);
    });
}