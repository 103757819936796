function handleClientLoad() {

    gapi.load('client:auth2', function() {
        gapi.client.init({
            discoveryDocs : ["https://people.googleapis.com/$discovery/rest?version=v1"],
            clientId      : $('meta[name="google-signin-client_id"]').attr('content'),      // get ID from <meta> tag in header
            scope         : 'email profile openid https://www.googleapis.com/auth/plus.login'

        }).then(function () {
            // Listen for sign-in state changes.
            gapi.auth2.getAuthInstance().isSignedIn.listen(loginWrapper);

            $('.sso-goog').click(function() {
                gapi.auth2.getAuthInstance().signIn();

                // Handle the initial sign-in state.
                loginWrapper(gapi.auth2.getAuthInstance().isSignedIn.get());
            });

            // $('.sso-goog-signOutcode').click(function() {
            //     gapi.auth2.getAuthInstance().signOut();
            // });

            function loginWrapper(isSignedIn) {

                if(isSignedIn) {
                    // Make an API call to the People API, and print the user's given name.
                    gapi.client.people.people.get({
                        'resourceName': 'people/me',
                        'personFields': 'metadata,coverPhotos,emailAddresses,genders,names,locales'

                    }).then(function(response) {
                        console.log(response);

                        if(response.status === 200) {
                            var meResp = response.result;

                            SSOLogin({
                                SSOID       : meResp.metadata.sources[0].id,
                                SSOProvider : 'google',
                                locale      : meResp.locales[0].value.substr(0,2),
                                first_name  : meResp.names[0].givenName,
                                last_name   : meResp.names[0].familyName,
                                email       : meResp.emailAddresses[0].value,
                                gender      : (meResp.genders) ? meResp.genders[0].value : "M",
                                currency    : "CAD"
                            });
                        }

                    }, function(reason) {
                        console.error(reason);
                    });
                }
            }
        });
    });
}
