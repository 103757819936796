function loginWrapper() {
    FB.api('/me?fields=id,email,currency,gender,locale,first_name,last_name', ["public_profile", "email"], function(meResp) {
        console.log('FB api /me?fields=id,email,currency,gender,locale,first_name,last_name', meResp);

        if(typeof meResp.error === 'undefined') {
            SSOLogin({
                SSOID: meResp.id,
                SSOProvider: 'facebook',
                locale: meResp.locale,
                first_name: meResp.first_name,
                last_name: meResp.last_name,
                email: meResp.email,
                gender: meResp.gender,
                currency: meResp.currency.user_currency
            });
        } else {
            showMsgBox('<strong>Facebook SSO</strong> ' + meResp.error.message);
        }
    });
}

$(function() {
    if($('.sso-fb').length) {

        window.fbAsyncInit = function() {
            FB.init({
                appId            : window.parlayApi.env.facebookAppId,
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v2.10'
            });
            FB.AppEvents.logPageView();
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        $('.sso-fb').click(function() {
            FB.getLoginStatus(function(response) {
                if (response.status === 'connected') {
                    console.log('Logged in.');
                    loginWrapper();
                }
                else {
                    FB.login();
                    loginWrapper();
                }
            });
        });
    }
});